//
// Colors
//

$_swp_yellow: #fedd03;
$_swp_dimmedyellow: darken($_swp_yellow, 5%);
$_swp_lightgray: #f7f7f7;
$_swp_panel: #333333;
$_swp_panel_section: #4F4F4F;
$_swp_darkgray: #222222;
$_swp_lightgray_control: $_swp_lightgray;

//
// Body
//

$body-bg: $_swp_panel;

//
// Input
//
$input-focus-color: $_swp_darkgray;
$input-focus-border-color: $_swp_darkgray;

//
// Links
//

$link-color: $_swp_darkgray;
$link-hover-decoration: none;

//
// Fonts
//

$font-family-sans-serif:
        // Safari for OS X and iOS (San Francisco)
        -apple-system,
          // Chrome < 56 for OS X (San Francisco)
        BlinkMacSystemFont,
          // Windows
        "Segoe UI",
          // Android
        "Roboto",
          // Basic web fallback
        "Helvetica Neue", Arial, sans-serif,
          // Emoji fonts
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

@import "~bootstrap/scss/bootstrap";
@import '~vue-resize/dist/vue-resize.css';

.dropdown-menu > .nav-link {
        color: $_swp_panel_section !important;
}

.navbar-dark {
        background-color: $_swp_darkgray;
}

.navbar-dark .navbar-brand {
        color: $_swp_yellow;
}

.text-brandyellow {
        color: $_swp_yellow;
}

.btn-brandyellow {
        color: $_swp_darkgray;
        background-color: $_swp_yellow;
}

.h-activealerts-card-content {
        max-height: 55vh;
}

.auto-pointer-events {
        pointer-events: auto;
}

.no-pointer-events {
        pointer-events: none;
}

.alert-legend-chip {
        @extend .font-weight-bold;
        padding: 4px;
        border-radius: 4px 4px 4px 4px;
}

html {
        overflow: hidden;
        height: 100%;
}

body {
        background-color: $body-bg !important;
        overflow: auto;
        height: 100%;
}

//
// Button overrides
//

.btn-primary {
        @include button-variant($_swp_panel_section, $_swp_lightgray_control);
}

//
// Tag overrides
//
